import { roleService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { RoleResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AddRole extends mixins(DialogMixin) {
  @Prop({
    required: false,
    default: () => {
      return null;
    },
    type: Object
  })
  public role!: RoleResource | null;

  public roleForm: {
    roleName: string;
    remark: string;
  } = {
    roleName: '',
    remark: ''
  };

  public roleFormRules = {
    roleName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('role.nameEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          roleService
            .checkRoleName(value, this.role?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.roleNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.role) {
      this.operationType = 'add';
      this.title = 'role.addRole';
      return;
    }
    this.operationType = 'edit';
    this.title = 'role.editRole';
    this.$nextTick(() => {
      Object.assign(this.roleForm, this.role);
    });
  }

  /**
   * Dialog 关闭动画结束时的回调
   */
  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.roleForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.roleForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await roleService.post({ ...this.roleForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await roleService.put({ ...this.roleForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.roleForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
